import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic8.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic9.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic10.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic11.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic12.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic13.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic14.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic15.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic16.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic17.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic18.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic19.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic20.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic21.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic22.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic23.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic24.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-3/pic25.jpeg",
];

export default function FathersDay2023() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Father's Day Celebrations</h1>

        <p>
          <strong>
            "When you need real understanding, when you need someone to care,
            when you need someone to guide you ... A father is always there."
          </strong>
          <br />
          <br />
          The learners of Pre-Primary and Primary Department of Chinmaya
          Vidyalaya, New Delhi, celebrated Father's Day in order to make their
          doting fathers feel special. The little learners paid respect and
          expressed gratitude towards their fathers in a spectacular way on 18th
          June. The tiny tots, with the help of their mothers, made a beautiful
          craft and yummy Oreo shake with ice cream as a gift for their beloved
          fathers. The students of classes I and II made beautiful cards and
          wrote a short note of gratitude inside them. The goodie bags made by
          classes III to V students contained a few of their father's favourite
          eatable things. The students made their daddies feel special.
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
